import React, { useState } from "react";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
// import GenericForm from "src/components/forms/GenericForm";
import { GenericForm } from "src/components/templateComponents";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup";
import findSiteType from "src/components/helpers/findSiteType";
import loadable from '@loadable/component'
import { getPageDataJsonPath } from "src/utils";
const FormPageWithMultipleVideos = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.FormPageWithMultipleVideos
});
const WatchDemo = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.WatchDemoForm
});

import { SEO } from "src/components/commonComponents";
const getMetaData = require("src/../helpers/getMetaData");

const FormPageTemplate = ({ pageContext, path }) => {
  const { fieldFormPageType, locale } = pageContext
  const marketoFormEntity = pageContext?.relationships?.fieldMarketoFormNode
  const marketoFormPopupEntity = pageContext?.relationships?.fieldMarketoFormPopup
  const { id, serial, gaLabel } = extractMarketoValues(
    marketoFormEntity?.fieldEmbedCode
  );
  const {id: marketoPopUpId, serial: marketoPopUpSerial, gaLabel: marketoPopUpGaLabel} = extractMarketoValues(
    marketoFormPopupEntity?.fieldEmbedCode
  )
  const [popup, setPopup] = useState(true);
  const [isFormLoaded, setFormLoaded] = useState(false)
  const [isFormWithPopupLoaded, setFormPopupLoad] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }
  const formPopupCallback = () => {
    setFormPopupLoad(true)
  }
  const fieldShowFormInPopup = pageContext?.fieldShowFormInPopup
  const checkForPopupForm = fieldFormPageType === 'multiple-hero-videos' && fieldShowFormInPopup

  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  const { formTag: formTagPopup } = checkForPopupForm ? useMarketoForm(marketoPopUpSerial, marketoPopUpId, formPopupCallback, "", marketoPopUpGaLabel, true, "", setPopup) : {formTag: null}
  const fieldFormHeader = marketoFormEntity?.fieldFormHeader
  const fieldFormPopHeader = marketoFormPopupEntity?.fieldFormHeader
  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if(hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })
  const heroImage = pageContext?.heroImage
  const OGMarkup = getOGMarkup(pageContext?.title, 'Form', heroImage);
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldContentVideoSchemaMarkup || pageContext?.fieldVideoSchemaMarkup)
  const siteClass = findSiteType('mc') ? "form-page-wrapper-mc" : findSiteType('ems') ? "form-page-wrapper-ems" : ""
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  const entityMetatags = getMetaData(pageContext?.metatag);
  const isDefaultDescription = entityMetatags[entityMetatags.length-1]['content'] === "Accruent's software helps workplace & asset management organizations unify their built environments for better management of people, places, and resources."
  switch(pageContext?.entityUrl?.path){
    case "/watch-product-demo-ems":{
      if (isDefaultDescription) {
        entityMetatags.pop()
      }
      entityMetatags.push(
        {name: 'description', content: 'Watch EMS demo videos to see how it allows you to seamlessly book a meeting with your team.'}
      )
      break;
    }
    case "/watch-product-demo-meridian":{
      if (isDefaultDescription) {
        entityMetatags.pop()
      }
      entityMetatags.push(
        {name: 'description', content: 'Watch a Meridian demo to see how it can streamline your document tasks and improve collaboration among your engineering, maintenance, operations, and EPC teams.'}
      )
      break;
    }
    default: {
      break;
    }
  }
  return (
    <>
      <div pagetype="taxonomy" id="form-pages" tid={pageContext?.entityId}>
        <SEO
          lang={locale}
          pageUrl={pageContext?.entityUrl?.path}
          title={pageContext?.title}
          meta={entityMetatags}
          alternatives={hreflangAlternatives}
          OGMarkup={OGMarkup}
          schemaMarkup={schemaMarkup}
          schemaMarkupVideo={schemaMarkupVideo}
          preloads= {[pageDataPath]}
        />
        <div>
          {formTag && <section className={"form-page-wrapper " + siteClass} >
            {fieldFormPageType === "default" &&
              <WatchDemo
                form={formTag}
                pageContext={pageContext}
                fieldFormHeader={fieldFormHeader}
                isFormLoaded={isFormLoaded}
              />
            }
            {fieldFormPageType === "multiple-hero-videos" &&
              <FormPageWithMultipleVideos
                form={formTag}
                pageContext={pageContext}
                fieldFormHeader={fieldFormHeader}
                isFormLoaded={isFormLoaded}
                isFormWithPopupLoaded={isFormWithPopupLoaded}
                popup={popup}
                setPopup={setPopup}
                fieldShowFormInPopup={fieldShowFormInPopup}
                formTagPopup={formTagPopup}
                fieldFormPopHeader={fieldFormPopHeader}
              />
            }
            {fieldFormPageType === "generic_form" &&
               <GenericForm
                form={formTag}
                pageContext={pageContext}
                fieldFormHeader={fieldFormHeader}
                isFormLoaded={isFormLoaded}
              />
            }
          </section>}
        </div>
      </div>
    </>
  );
};

export default FormPageTemplate;
